module.exports = [{
      plugin: require('/Users/masaosaeki/Documents/hp/homepage/apps/gatsby-starter-newage/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"iPhoneアプリ ペンギン日記","short_name":"Landing","start_url":"/gatsby-starter-newage/","background_color":"#0F6C96","theme_color":"#0F6C96","display":"standalone","icon":"src/assets/img/website-icon.png"},
    },{
      plugin: require('/Users/masaosaeki/Documents/hp/homepage/apps/gatsby-starter-newage/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/masaosaeki/Documents/hp/homepage/apps/gatsby-starter-newage/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
